*,
*::before,
*::after {
  box-sizing:border-box
}
body {
  margin:0;
  padding:0;
  min-height:100vh;
  color:rgba(0,0,0,.87);
  font-family:"Roboto","Noto Sans",sans-serif;
  font-weight:400;
  line-height:1.4;
  font-size:1rem
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.active {
  color: rgba(23, 144, 82, 1);
  border-bottom: 2px solid rgba(23, 144, 82, 1);
}

.sideActive {
  border-left: 2px solid rgba(23, 144, 82, 1);
}

body *:not(input) {
  text-transform: capitalize !important;
}

.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 2px 8px rgba(0,0,0,.2);
  z-index: 1;
}

.sidFixed {
  position: fixed;
  top: 110px;
}
.sidFixedBottom {
  position: absolute;
  bottom: 0;
}

.hardwareTopfixed {
  position: fixed;
  top: 64px;
  left: 0;
  width: 100%;
  background-color: #fff !important;
  box-shadow: 0 2px 8px rgba(0,0,0,.2);
  height: auto !important;
  z-index: 1;
}

.hide {
  display: none;
}

.leftBody {
  margin-inline-start: 250px;
}